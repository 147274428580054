<template>
	<v-container fluid tag="section">
		<!--<v-row
			><v-col cols="auto" md="12" class="">-->
		<v-container class="fill-height pa-0">
			<v-row class="">
				<v-col
					cols="12"
					sm="4"
					class="flex-grow-1 flex-shrink-0 no-gutters elevation-1 pa-0 mr-6"
					style=""
				>
					<v-responsive class="overflow-y-auto fill-height" height="525">
						<v-list subheader>
							<v-list-item-group v-model="activeChat">
								<template v-for="(item, index) in parents">
									<v-list-item :key="`parent${index}`" :value="item.id">
										<v-list-item-avatar color="grey lighten-1 white--text">
											<v-icon> chat_bubble </v-icon>
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title v-text="item.title" />
											<v-list-item-subtitle v-text="'hi'" />
										</v-list-item-content>
										<v-list-item-icon>
											<v-icon
												:color="item.active ? 'deep-purple accent-4' : 'grey'"
											>
												chat_bubble
											</v-icon>
										</v-list-item-icon>
									</v-list-item>
									<v-divider :key="`chatDivider${index}`" class="my-0" />
								</template>
							</v-list-item-group>
						</v-list>
					</v-responsive>
				</v-col>

				<v-col
					cols="auto"
					class="flex-grow-1 flex-shrink-0 no-gutters elevation-1 pa-0 ml-2"
				>
					<v-responsive
						v-if="activeChat"
						class="overflow-y-hidden fill-height"
						height="auto"
					>
						<v-card flat class="d-flex flex-column fill-height py-0 my-0">
							<v-card-title class="primary white--text">
								john doe
							</v-card-title>
							<v-card-text
								class="flex-grow-1 overflow-y-auto py-2 messageBox mt-0"
							>
								<!--opcion 1-->
								<div
									v-if="tipo == 1"
									v-for="(message, index) in messages"
									:key="index"
									:name="`item-${index}`"
								>
									<div
										:class="
											message.me ? 'messageFromUser' : 'messageFromChatGpt'
										"
									>
										<div
											:class="
												message.me
													? 'userMessageWrapper'
													: 'chatGptMessageWrapper'
											"
										>
											<div
												:class="
													message.me
														? 'userMessageContent'
														: 'chatGptMessageContent'
												"
											>
												{{ message.content }}
												<sub class="ml-2" style="font-size: 0.7rem">{{
													message.created_at
												}}</sub>
											</div>
										</div>
									</div>
								</div>
								<!--opcion 1-->
								<!--opcion 2-->

								<template v-for="(msg, i) in messages" v-else>
									<div :class="{ 'd-flex flex-row-reverse': msg.me }">
										<v-menu offset-y>
											<template v-slot:activator="{ on }">
												<v-hover v-slot:default="{ hover }">
													<v-chip
														:color="msg.me ? 'grey lighten-1' : 'primary-2'"
														dark
														style="height: auto; white-space: normal"
														class="px-4 py-1 mb-2"
														v-on="on"
													>
														{{ msg.content }}
														<sub class="ml-2" style="font-size: 0.5rem">{{
															msg.created_at
														}}</sub>
														<v-icon v-if="hover" small> expand_more </v-icon>
													</v-chip>
												</v-hover>
											</template>
											<v-list>
												<v-list-item>
													<v-list-item-title>delete</v-list-item-title>
												</v-list-item>
											</v-list>
										</v-menu>
									</div>
								</template>
							</v-card-text>
							<v-card-text
								class="flex-shrink-1"
								style="background-color: #f0f0f0"
							>
								<!--append-outer-icon="send"
									@click:append-outer="messages.push(messageForm)"-->
								<v-row>
									<v-col cols="12" sm="11" class="flex-grow-1 flex-shrink-0"
										><v-text-field
											v-model="messageForm.content"
											label="escribe tu mensaje..."
											type="text"
											no-details
											outlined
											@keyup.enter="messages.push(messageForm)"
											hide-details
											style="background-color: #fff"
									/></v-col>
									<v-col cols="12" sm="1" class="my-2 px-1"
										><v-btn fab dark small color="primary">
											<v-icon
												@click="messages.push(messageForm)"
												class="white--text"
												>mdi-send</v-icon
											>
										</v-btn></v-col
									>
								</v-row>
							</v-card-text>
						</v-card>
					</v-responsive>
				</v-col>
			</v-row> </v-container
		><!--</v-col
		></v-row>-->
	</v-container>
</template>




<script>
	import axios from "axios";

	export default {
		name: "ChatBox",
		data() {
			return {
				showModal: false,
				tipo: 1,
				activeChat: 1,
				parents: [
					{
						id: 1,
						title: "john doe",
						active: true,
					},
					{
						id: 3,
						title: "scarlett",
						active: false,
					},
					{
						id: 4,
						title: "scarlett",
						active: false,
					},
					{
						id: 5,
						title: "scarlett",
						active: false,
					},
					{
						id: 6,
						title: "scarlett",
						active: false,
					},
					{
						id: 7,
						title: "scarlett",
						active: false,
					},
					{
						id: 8,
						title: "scarlett",
						active: false,
					},
					{
						id: 9,
						title: "scarlett",
						active: false,
					},
					{
						id: 10,
						title: "scarlett",
						active: false,
					},
					{
						id: 11,
						title: "scarlett",
						active: false,
					},
					{
						id: 12,
						title: "scarlett",
						active: false,
					},
					{
						id: 13,
						title: "scarlett",
						active: false,
					},
					{
						id: 14,
						title: "scarlett",
						active: false,
					},
				],
				messages: [
					{
						content: "lorem ipsum",
						me: true,
						created_at: "11:11am",
					},
					{
						content: "dolor",
						me: false,
						created_at: "11:11am",
					},
					{
						content: "dolor",
						me: false,
						created_at: "11:11am",
					},
					{
						content: "dolor",
						me: false,
						created_at: "11:11am",
					},
					{
						content: "dolor",
						me: true,
						created_at: "11:11am",
					},
					{
						content: "dolor",
						me: false,
						created_at: "11:12am",
					},
					{
						content: "dolor",
						me: false,
						created_at: "11:14am",
					},
				],
				messageForm: {
					content: "",
					me: true,
					created_at: "11:11am",
				},
			};
		},
		methods: {
			async sendMessage(message) {
				if (message != "") {
					this.messages.push({
						from: "user",
						data: message,
					});

					setTimeout(() => {
						this.messages.push({
							from: "chatGpt",
							data: "No conectado",
						});
					}, 1000);
					/*await axios
																																																																																																																																																																																																															          .post(
																																																																																																																																																																																																															            "https://api.openai.com/v1/completions",
																																																																																																																																																																																																															            {
																																																																																																																																																																																																															              model: "gpt-3.5-turbo",
																																																																																																																																																																																																															              prompt: message,
																																																																																																																																																																																																															              max_tokens: 20,
																																																																																																																																																																																																															            },
																																																																																																																																																																																																															            {
																																																																																																																																																																																																															              headers: {
																																																																																																																																																																																																															                "Content-Type": "application/json",
																																																																																																																																																																																																															                Authorization: `Bearer sk-proj-6VuWokkMANKeCukrKCJwT3BlbkFJthFzimq2sSdB2EnP6HG3`,
																																																																																																																																																																																																															              },
																																																																																																																																																																																																															            }
																																																																																																																																																																																																															          )
																																																																																																																																																																																																															          .then((response) => {
																																																																																																																																																																																																															            this.messages.push({
																																																																																																																																																																																																															              from: "chatGpt",
																																																																																																																																																																																																															              data: response.data.choices[0].text.trim(),
																																																																																																																																																																																																															            });
																																																																																																																																																																																																															          })
																																																																																																																																																																																																															          .catch((error) => {
																																																																																																																																																																																																															            console.error("Error:", error);
																																																																																																																																																																																																															            this.messages.push({
																																																																																																																																																																																																															              from: "chatGpt",
																																																																																																																																																																																																															              data: "No conectado",
																																																																																																																																																																																																															            });
																																																																																																																																																																																																															          });
																																																																																																																																																																																																															        */
				} else {
					this.messages.push({
						from: "chatGpt",
						data: "Debes ingresar una consulta",
					});
				}
			},
		},
	};
</script>

<style>
/*
.floating-button {
	position: fixed;
	bottom: 16px;
	right: 16px;
	z-index: 1000;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

.chatbox-contenedor {
	position: fixed;
	bottom: 24px !important;
	right: 74px !important;
	z-index: 1000;
}

.contenedor {
	width: 360px;
	height: 500px;
	background-color: white;
	border-radius: 8px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	overflow: hidden;
	font-family: "Roboto", sans-serif;
}
*/
.messageBox {
	padding: 16px;
	flex-grow: 1;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	gap: 12px;
	background-color: #fafafa;
}

.messageFromChatGpt {
	float: right;
}
.messageFromUser,
.messageFromChatGpt {
	display: flex;
}

.messageBox {
	max-height: 400px;
	overflow-y: auto;
	padding: 0 16px;
	border-top: 1px solid #f0f0f0;
	border-bottom: 1px solid #f0f0f0;
	flex-grow: 1;
}

.messageFromUser,
.messageFromChatGpt {
	display: flex;
	margin-bottom: 8px;
}

.userMessageWrapper,
.chatGptMessageWrapper {
	display: flex;
	flex-direction: column;
}

.userMessageWrapper {
	align-self: flex-end;
}

.chatGptMessageWrapper {
	align-self: flex-start;
}

.userMessageContent,
.chatGptMessageContent {
	max-width: 100%;
	padding: 8px 12px;
	border-radius: 18px;
	margin-bottom: 2px;
	font-size: 16px !important;
	line-height: 1.4;
}

.userMessageContent {
	background-color: #1877f2;
	color: white;
	border-top-left-radius: 0;
}

.chatGptMessageContent {
	background-color: #ededed;
	color: #222;
	border-top-right-radius: 0;
}

.userMessageTimestamp,
.chatGptMessageTimestamp {
	font-size: 12px !important;
	color: #999;
	margin-top: 2px;
}

.userMessageTimestamp {
	align-self: flex-end;
}

.chatGptMessageTimestamp {
	align-self: flex-start;
}

.inputContainer {
	display: flex;
	align-items: center;
	padding: 8px;
	background-color: #e0e0e0;
}

.messageInput {
	flex-grow: 1;
	border: none;
	outline: none;
	padding: 12px;
	font-size: 16px;
	background-color: white;
	border-radius: 24px;
	margin-right: 8px;
}

.askButton {
	background-color: #1877f2;
	color: white;
	font-size: 16px;
	padding: 8px 10px;
	border: none;
	outline: none;
	cursor: pointer;
	border-radius: 24px;
	transition: background-color 0.3s ease-in-out;
}

.askButton:hover {
	background-color: #145cb3;
}

/*
@media (max-width: 480px) {
	.contenedor {
		width: 100%;
		max-width: none;
		border-radius: 0;
	}
}
.chatbox-contenedor {
	position: fixed;
	bottom: 24px;
	right: 24px;
	z-index: 1000;
}
*/
.messageBox {
	padding: 16px;
	flex-grow: 1;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.messageFromUser,
.messageFromChatGpt {
	display: flex;
}
</style>
